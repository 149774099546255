import request from "@/utils/http.js";

//授课证列表
export function giveLessonsList(options){
  return request({
    url: "/medalGiveLessons/giveLessonsList",
    method: "post",
    data: options,
  });
}

//授课证介绍
export function queryIntroduce(options){
  return request({
    url: "/medalGiveLessons/queryIntroduce",
    method: "post",
    data: options,
  });
}

//授课证作品分页查询
export function giveLessonsWorks(options){
  return request({
    url: "/medalGiveLessons/giveLessonsWorks",
    method: "post",
    data: options,
  });
}

//授课证详情
export function giveLessonsInfo(options){
  return request({
    url: "/medalGiveLessons/giveLessonsInfo",
    method: "post",
    data: options,
  });
}

// 查询作品信息
export function workInfo(options){
  return request({
    url: "/medalGiveLessons/workInfo",
    method: "post",
    data: options,
  });
}

// 上传作品
export function uploadWork(options){
  return request({
    url: "/medalGiveLessons/uploadWork",
    method: "post",
    data: options,
  });
}

// 授课证管理 - 报名
export function signUp(options){
  return request({
    url: "/medalGiveLessons/signUp",
    method: "post",
    data: options,
  });
}

// 文件上传下载API - 获取七牛上传token
export function getToken(options){
  return request({
    url: "/qiniuFile/getToken",
    method: "post",
    data: options,
  });
}


// 授课证管理 - 学段下拉框
export function selectPhaseList(options){
  return request({
    url: "/mobile/selectPhaseList",
    method: "post",
    data: options,
  });
}

// 授课证管理 - 地区下拉框
export function areaListByParentId(options){
  return request({
    url: "/mobile/areaListByParentId",
    method: "post",
    data: options,
  });
}

//用户信息
export function selectUserDetail(options) {
  return request({
      url: '/mobile/selectUserDetail',
      method: 'post',
      data: options
  })
}