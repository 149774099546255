<template>
  <div class="work_main">
    <div class="work_wrapper" v-if="obj != null">
      <w-header :details='{...obj,courseName,courseId}'/>
      <div class="cont">
        <a-row :gutter="60">
          <a-col class="gutter-row" :span="16">
            <div class="img_box">
              <img
                v-if="!obj.fileAddress"
                src="@/assets/imgs/cer/work_icon.png"
                width="100%"
                alt=""
              />
              <video
                v-else
                :src="QiNiuPath + obj.fileAddress"
                width="732"
                height="560"
                controls
              ></video>
            </div>
          </a-col>
          <a-col class="gutter-row" :span="8">
            <div class="text_top_box">
              <h2 v-text="obj.name"></h2>
              <span class="serial_num">NO.{{obj.id}}</span>
              <div class="author_name" v-if="false">
                <label>作者名</label>
                <span></span>
              </div>
            </div>
            <div class="text_down_box">
              <h5>作品描述</h5>
              <p v-text="obj.description!=null&&obj.description!=''?obj.description:''">        
              </p>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
const WHeader = () => import("./components/workDetails/pageHeader.vue");
import { workInfo,giveLessonsInfo } from "@/apis/certificate.js";
import { mapState } from "vuex";
export default {
  name: "work_details",
  data() {
    return {
      obj: null,
      courseName:'',
    };
  },
  created() {
    // 获取用户信息，如果有加载模块页面， 没有跳转登录页
			if(!this.proToken) {
				this.$router.push({
					path: "/VclassesCenterLogin",
					query: {
						navtype: 'certificate',
					}
				})
			}
    this.getDetails();
    this.getWorkInfo();
  },
  components: {
    "w-header": WHeader,
  },
  computed: {
    ...mapState({
      QiNiuPath: (state) => state.indexStore.QiNiuPath,
      proToken: state => state.indexStore.B_PRO_TOKEN,
    }),
  },
  methods: {
    getDetails(){
      let id=this.$route.query.mId;
      giveLessonsInfo({id}).then(res=>{
        let msg=res.data;
        if(msg.status==0){
          this.courseName=msg.result.courseName;
          this.courseId=this.$route.query.mId;
        }
      })
    },
    getWorkInfo() {
      workInfo({
        id: this.$route.query.wId,
      }).then((res) => {
        let msg = res.data;
        if (msg.status == 0) {
          this.obj = msg.result;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
//作者名通用样式
%author_styke {
  font-size: 16px;
  font-weight: 500;
  color: #606978;
  line-height: 22px;
}

.work_main {
  background-color: #f8f9fa;
  min-height: calc(100vh - 160px);
  max-height: auto;
  padding-top: 23px;
  .work_wrapper {
    width: 1200px;
    height: 750px;
    margin: 0 auto;
    padding: 29px 29px 29px 42px;
    background-color: #fff;
    .cont {
      width: 100%;
      padding-top: 24px;
      .img_box {
        height: 560px;
        text-align: center;
        overflow: hidden;
      }
      .text_top_box {
        padding-right: 31px;
        padding-top: 24px;
        h2 {
          font-size: 28px;
          font-weight: 500;
          color: #333a48;
          line-height: 40px;
        }
        .serial_num {
          display: block;
          font-size: 14px;
          font-weight: 500;
          color: #5d5aed;
          line-height: 20px;
          margin-top: 6px;
        }
        .author_name {
          margin-top: 12px;
          label {
            @extend %author_styke;
          }
          span {
            @extend %author_styke;
          }
        }
      }
      .text_down_box {
        margin-top: 12px;
        padding-right: 31px;
        padding-top: 27px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          border: 1px solid #f6f6f6;
          width: calc(100% - 27px);
          height: 1px;
        }
        h5 {
          font-size: 16px;
          font-weight: 500;
          color: #606978;
          line-height: 22px;
          margin-bottom: 8px;
        }
        p {
          width: 260px;
          font-size: 14px;
          font-weight: 400;
          color: #aeb2b7;
          line-height: 20px;
          word-break: break-all;
        }
      }
    }
  }
}
</style>